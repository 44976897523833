.terms {


  font-size: 11pt;
  @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Verdana;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"HKGrotesk-Regular";
	panose-1:0 0 5 0 0 0 0 0 0 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	text-align:justify;
	
	font-family:"Verdana",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	text-align:justify;
	
	font-family:"Verdana",sans-serif;}
p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	text-align:justify;
	
	font-family:"Verdana",sans-serif;}
p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	text-align:justify;
	
	font-family:"Verdana",sans-serif;}
p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	text-align:justify;
	
	font-family:"Verdana",sans-serif;}
p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	text-align:justify;
	
	font-family:"Verdana",sans-serif;}
p.Body, li.Body, div.Body
	{mso-style-name:Body;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:0cm;
	text-align:justify;
	text-indent:0cm;
	
	font-family:"Verdana",sans-serif;}
p.Level1, li.Level1, div.Level1
	{mso-style-name:"Level 1";
	mso-style-link:"Level 1 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:42.55pt;
	text-align:justify;
	text-indent:-42.55pt;
	
	font-family:"Verdana",sans-serif;}
p.Level2, li.Level2, div.Level2
	{mso-style-name:"Level 2";
	mso-style-link:"Level 2 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:42.55pt;
	text-align:justify;
	text-indent:-42.55pt;
	
	font-family:"Verdana",sans-serif;}
p.Level3, li.Level3, div.Level3
	{mso-style-name:"Level 3";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:92.15pt;
	text-align:justify;
	text-indent:-49.6pt;
	
	font-family:"Verdana",sans-serif;}
p.Level4, li.Level4, div.Level4
	{mso-style-name:"Level 4";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:155.95pt;
	text-align:justify;
	text-indent:-63.8pt;
	
	font-family:"Verdana",sans-serif;}
p.Level5, li.Level5, div.Level5
	{mso-style-name:"Level 5";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:155.95pt;
	text-align:justify;
	text-indent:-63.8pt;
	
	font-family:"Verdana",sans-serif;}
p.aDefinition, li.aDefinition, div.aDefinition
	{mso-style-name:"\(a\) Definition";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:42.55pt;
	text-align:justify;
	text-indent:-42.55pt;
	
	font-family:"Verdana",sans-serif;}
p.iDefinition, li.iDefinition, div.iDefinition
	{mso-style-name:"\(i\) Definition";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:92.15pt;
	text-align:justify;
	text-indent:-49.6pt;
	
	font-family:"Verdana",sans-serif;}
span.Level1asHeadingtext
	{mso-style-name:"Level 1 as Heading \(text\)";
	font-weight:bold;}
span.CrossReference
	{mso-style-name:"Cross Reference";
	font-weight:bold;}
span.Level2Char
	{mso-style-name:"Level 2 Char";
	mso-style-link:"Level 2";
	font-family:"Verdana",sans-serif;}
span.Level1Char
	{mso-style-name:"Level 1 Char";
	mso-style-link:"Level 1";
	font-family:"Verdana",sans-serif;}
p.Body1, li.Body1, div.Body1
	{mso-style-name:"Body 1";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:42.55pt;
	text-align:justify;
	text-indent:0cm;
	
	font-family:"Verdana",sans-serif;}
p.Level6, li.Level6, div.Level6
	{mso-style-name:"Level 6";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:184.3pt;
	text-align:justify;
	text-indent:-1.0cm;
	
	font-family:"Verdana",sans-serif;}
p.Level7, li.Level7, div.Level7
	{mso-style-name:"Level 7";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt !important;
	margin-left:212.65pt;
	text-align:justify;
	text-indent:-1.0cm;
	
	font-family:"Verdana",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;
	font-family:"Verdana",sans-serif;}
.MsoChpDefault
	{font-family:"Calibri",sans-serif;}
 /* Page Definitions */
 @page WordSection1
	{size:612.0pt 792.0pt;
	margin:72.0pt 25.9pt 72.0pt 33.25pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}

  strong, b {
    font-weight: bold;
    font-family: 'HKGrotesk-Black';
  }

p {
  line-height: 18px;
}
}
