.pac-container {
  background-color: #000;
  color: #fff;
  border: 1px solid #FFD600;
  border-radius: 20px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-family: 'HKGrotesk-Light';
}

.pac-icon {
  color: #FFD600;
  font-weight: 300;
}

.pac-item {
  color: #fff;
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  font-family: 'HKGrotesk-Light';
  font-size: 14px;
}

.pac-item-query {
  color: #b39c2c;
  font-family: 'HKGrotesk-Black';
  font-size: 14px;
}

.pac-matched {
  color: #FFD600;
  font-family: 'HKGrotesk-Black';
  font-size: 14px;
}

.pac-item:hover {
  background-color: #000;
  color: #fff;
  font-weight: 900;
}

.pac-logo:after {
  padding: 10px 0;

  display: none;

}

.pac-target-input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

/*
.pac-icon {
  background-image: url('./assets/locationMark.svg') !important;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 14px 18px;
}
*/