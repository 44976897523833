.terms {
  font-family: 'HKGrotesk-Regular';
  line-height: 18px;
  color: #fff;
  font-size: 11pt;

  line-height: 18px !important;
  p {
    line-height: 18px;
    display: block !important;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;

    line-height: 18px !important;
    padding-left: -15px !important;
  }

  strong {
    font-weight: bold;
    font-family: 'HKGrotesk-Black';
  }

  li {
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

  p {
    margin: 0;
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

  @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"HK Grotesk Regular";
	panose-1:0 0 5 0 0 0 0 0 0 0;}
@font-face
	{font-family:"HK Grotesk Bold";
	panose-1:0 0 8 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Helvetica Neue";
	panose-1:2 0 5 3 0 0 0 2 0 4;}
@font-face
	{font-family:"\@Arial Unicode MS";
	panose-1:2 11 6 4 2 2 2 2 2 4;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;
	border:none;}
p.Body, li.Body, div.Body
	{mso-style-name:Body;
	margin:0cm;
	font-size:11.0pt;
	font-family:"Helvetica Neue";
	color:black;
	border:none;}
.MsoChpDefault
	{font-size:11.0pt;
	border:none;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 27.15pt 72.0pt 29.45pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}

  strong, b {
    font-weight: bold !important;
    font-family: 'HKGrotesk-Black';
  }

p {
  line-height: 18px;
  color:#fff !important;
}

}
