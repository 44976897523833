.privacy-policy {
  font-family: 'HKGrotesk-Regular';
  line-height: 18px;

  line-height: 18px !important;
  p {
    line-height: 18px;
    display: block !important;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;

    line-height: 18px !important;
    padding-left: -15px !important;
  }

  strong {
    font-weight: bold;
    font-family: 'HKGrotesk-Black';
  }

  li {
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

  p {
    margin: 0;
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

}
